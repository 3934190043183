<template>
  <div>
    <top-data></top-data>
    <div class="venueArea">
      <el-table :data="tableData" stripe highlight textcenter style="width: 100%">
        <el-table-column prop="venueImage" label="场地图片" align="center">
          <template slot-scope="scope">
            <el-image style="width: 100px; height: 100px" :src="scope.row.venueImage"></el-image>
          </template>
        </el-table-column>
        <el-table-column prop="venueName" label="场地名称" align="center"></el-table-column>
        <el-table-column prop="matchType" label="场制" align="center">
          <template slot-scope="scope">
            <span v-if="scope.row.matchType == 1">一人制</span>
            <span v-if="scope.row.matchType == 2">二人制</span>
            <span v-if="scope.row.matchType == 3">三人制</span>
            <span v-if="scope.row.matchType == 4">四人制</span>
            <span v-if="scope.row.matchType == 5">五人制</span>
            <span v-if="scope.row.matchType == 6">六人制</span>
            <span v-if="scope.row.matchType == 7">七人制</span>
            <span v-if="scope.row.matchType == 8">八人制</span>
            <span v-if="scope.row.matchType == 9">九人制</span>
            <span v-if="scope.row.matchType == 10">十人制</span>
            <span v-if="scope.row.matchType == 11">十一人制</span>
          </template>
        </el-table-column>
        <el-table-column prop="areaType" label="室内/外" align="center"></el-table-column>
        <el-table-column prop="people" label="默认人数" align="center">
          <template slot-scope="scope">
            <div>拼场：{{ scope.row.matchType * 2 }}-{{ scope.row.matchType * 3 }}人</div>
            <div>飞盘：{{ scope.row.matchType * 2 }}-{{ scope.row.matchType * 4 }}人</div>
          </template>
        </el-table-column>
        <el-table-column prop="openingHours" label="场地开放时间" align="center"></el-table-column>
        <el-table-column label="操作" align="center" style="width: 100%; white-space: nowrap">
          <template slot-scope="scope">
            <el-button size="small" @click="editVenue(scope.row)">修改</el-button>
            <el-button size="small" @click="deleteVenue(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="bottom_btn">
      <el-button class="addVenue" @click="addVenue">新增</el-button>
    </div>

    <!-- 场地详情弹出框 -->
    <el-dialog :title="title" :visible.sync="dialogVisible" width="30%" @close="closeDialog">
      <el-form label-width="160px" ref="form" :model="formData" :rules="rules">
        <el-form-item label="场地名称：" prop="venueName">
          <el-input size="small" v-model="formData.venueName" maxlength="10"></el-input>
        </el-form-item>
        <el-form-item label="场制：" prop="matchType">
          <el-select size="small" v-model="formData.matchType" placeholder="请选择场制">
            <el-option
              v-for="item in orderServeData.fieldSystem"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="室内外：" prop="areaType">
          <el-select size="small" v-model="formData.areaType">
            <el-option
              class=""
              v-for="item in orderServeData.roomType"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="默认拼场人数：">
          <span>{{ this.pitchMin || 0 }}-{{ this.pitchMax || 0 }} /人</span>
        </el-form-item>
        <el-form-item label="默认飞盘人数：">
          <span>{{ this.flyMin || 0 }}-{{ this.flyMax || 0 }} /人</span>
        </el-form-item>
        <el-form-item label="开始时间：" prop="openingHours">
          <el-select size="small" v-model="formData.openingHours">
            <el-option
              v-for="item in planTime.venueTime"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="场地图片：" prop="venueImage">
          <f-image-upload
            v-model="formData.venueImage"
            style="margin-left: 0"
            :width="'220px'"
            :height="'124px'"
          ></f-image-upload>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button size="small" @click="dialogVisible = false">取 消</el-button>
        <el-button
          size="small"
          style="background-color: #134f7a; border: none"
          type="primary"
          @click="handleSubmit"
          >确 定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>
<script>
import orderServeData from '../../apidata/orderServe.json';
import planTime from '../../apidata/planTime.json';
import TopData from '../../common/components/normal/TopData.vue';
import FImageUpload from '@/common/components/Upload/f-image-upload.vue';
export default {
  components: { FImageUpload,TopData },
  name: 'VenueManage',
  data() {
    return {
      dialogVisible: false,
      tableData: [],
      orderServeData,
      planTime: planTime,
      formData: {
        venueName: '',
        matchType: undefined,
        areaType: '',
        openingHours: '',
        defaultPrice: 0,
        venueImage: '',
      },
      rules: {
        venueName: [{ message: '请输入场地名称', required: true, trigger: 'blur' }],
        matchType: [{ message: '请选择场制', required: true, trigger: 'change' }],
        areaType: [{ message: '请选择室内外', required: true, trigger: 'change' }],
        venueImage: [{ message: '请上传场地图片', required: true, trigger: 'change' }],
      },
      title: '',
    };
  },

  computed: {
    pitchMin() {
      return this.formData.matchType * 2;
    },
    pitchMax() {
      return this.formData.matchType * 3;
    },
    flyMin() {
      return this.formData.matchType * 2;
    },
    flyMax() {
      return this.formData.matchType * 4;
    },
  },

  mounted() {
    this.init();
  },

  methods: {
    async init() {
      const { res } = await this.$http.get('/FieldVenue/GetFieldVenueList');
      this.tableData = res.data || [];
    },
    // 新增弹窗
    addVenue() {
      this.dialogVisible = true;
      this.formData.openingHours = '08:00';
      this.title = '新增场地';
    },
    // 删除场地
    deleteVenue(row) {
      this.$confirm('确认删除该场地吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(async () => {
        const { res } = await this.$http.post(`/FieldVenue/RemoveVenue/${row.venueId}`);
        if (res.isSuccess) {
          this.$showSuccess('删除成功');
          this.init();
        }
      });
    },
    // 修改场地信息
    editVenue(row) {
      this.dialogVisible = true;
      this.formData = this.cloneDeep(row);
      this.title = '编辑场地';
    },
    closeDialog() {
      this.dialogVisible = false;
      this.title = '';
      this.formData = {};
      this.$refs.form.clearValidate();
    },

    // 保存信息
    handleSubmit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.$confirm('确认保存场地信息吗？', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
          }).then(async () => {
            const { res } = await this.$http.post('/FieldVenue/AddOrUpdateVenue', this.formData);
            if (res.isSuccess) {
              this.$showSuccess('保存成功');
              this.dialogVisible = false;
              this.init();
            }
          });
        }
      });
    },

    // 深拷贝
    cloneDeep(target) {
      // 传入null，还是返回null
      if (target === null) {
        return target;
      }
      // 判断是否是对象
      if (typeof target === 'object') {
        // 兼容数组与对象
        let obj = Array.isArray(target) ? [] : {};
        for (let key in target) {
          obj[key] = this.cloneDeep(target[key]);
        }
        return obj;
      } else {
        return target;
      }
    },
  },
};
</script>

<style scoped>
.venueArea {
  margin-top: 30px;
}

.bottom_btn {
  display: flex;
  justify-content: center;
  margin-top: 50px;
}

.addVenue {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80px;
  background-color: #134f7a;
  color: #fff;
}
</style>
